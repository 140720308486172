<template>
  <div :style="{height: deviceHeight}">
    <!-- ((isInPC() || isInApp) && !isiPhoneSafari()) ? '100vh' : '89vh' -->

    <!-- <ClientOnly>
      <div
        class="base-default-layout w-full"
        :style="{
          // paddingTop: layoutTop
        }"
      >
      <BaseHeaderNavigation v-if="isHeaderNavigationShow" :app-tool-bar-height="sharedStore?.C5ToolBarHeight" />
      </div>
    </ClientOnly> -->
    <div
      class="base-default-layout-content h-full overflow-y-auto"
      :style="{ paddingTop: layoutTop }"
    >
      <slot />
    </div>
    <BaseBottomNavigation v-if="sharedStore.isShowBottomNavigation" />
    <input id="keyboard" type="text">
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import Cookies from 'js-cookie'
// import { px2rem } from '../utils/index'
import { getVantNavBarHeight, disableZoom } from '@/utils/index'
import { useSharedStore } from '~/stores/shared'
import { isInChrome, isiOSVersionAbove15 } from '~/utils/device'
// import { isAndroidDevice, isiPhone, isiPhoneSafari } from '~/utils/device'

const sharedStore = useSharedStore()

const layoutTop = computed(() => {
  return `${px2rem(46) + px2rem(sharedStore?.C5ToolBarHeight || 0)}rem`
})

const deviceHeight = ref('100vh')

watch(deviceHeight, value => {
  console.log(`高度： ${value}`)
})

onMounted(async () => {
  await nextTick()

  const ua = navigator.userAgent
  console.log(ua)

  // console.log(`页面高度：${(isiPhoneSafari() || (isMobile() && isInChrome())) ? isiOSVersionAbove15() ? '89vh' : '85vh' : '100vh'}`)
  console.log(`isiPhoneSafari：${isiPhoneSafari()}`)
  console.log(`isMobile：${isMobile()}`)
  console.log(`isInChrome：${isInChrome()}`)
  console.log(`isiOSVersionAbove15: ${isiOSVersionAbove15()}`)

  ;(window as any).callKeyboard = function () {
    const input = document.getElementById('keyboard')
    input?.focus()
  }

  // isInPC.value = !/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator?.userAgent) && window.screen.width > 430

  sharedStore.setHeaderHeight(getVantNavBarHeight())
  sharedStore.setC5ToolBarHeight(Number(Cookies.get('C5ToolBarHeight') || 0))

  native?.hiddenToolBar?.()
  disableZoom()

  if (isInApp) {
    deviceHeight.value = '100vh'
    return
  }

  // safari
  if (isiPhoneSafari()) {
    // ios15 及以上
    if (isiOSVersionAbove15()) {
      deviceHeight.value = '89vh'
    } else {
      deviceHeight.value = '85vh'
    }
  } else if (isMobile() && isAndroidDevice() && !isInQQ() && !isInWechat()) {
    // 安卓浏览器
    if ((/HeyTapBrowser/i.test(ua))) {
      // 欢太浏览器 - oppo
      deviceHeight.value = `${px2rem(window.document.documentElement.clientHeight)}rem`
      return
    }
    if ((/VivoBrowser/i.test(ua))) {
      // vivo
      deviceHeight.value = 'calc(100vh - 50px)'
      return
    }
    if ((/MiuiBrowser/i.test(ua))) {
      // 小米
      // deviceHeight.value = 'calc(100vh - 60px)'
      deviceHeight.value = `${px2rem(window.document.documentElement.clientHeight - 55)}rem`

      // innerHeight基准 745
      // innerHeight差值 = window.innerHeight - 745
      // 需要减去的高度 = -35 + window.innerHeight - 745
      console.log(`window.screen.height: ${window.screen.height}; window.innerHeight: ${window.innerHeight}; window.document.documentElement.clientHeight: ${window.document.documentElement.clientHeight}`)
      // deviceHeight.value = `${px2rem(window.innerHeight - (window.innerHeight / 745 * 55))}rem`
      return
    }
    // deviceHeight.value = `${px2rem(window.document.documentElement.clientHeight - 46)}rem`
    deviceHeight.value = 'calc(100vh - 50px)'
  } else {
    // pc端 || 第三方app
    deviceHeight.value = '100vh'
  }
})

</script>

<style lang="scss" scoped>
#keyboard {
  position: absolute;
  width: 100px;
  left: -9999px;
}

.h-view-full {
  height: 100vh;
}
</style>
