<template>
  <client-only>
    <van-tabbar
      id="menu-list"
      :fixed="true"
      :route="true"
      :replace="true"
      active-color="#333333"
      inactive-color="#999999"
      :safe-area-inset-bottom="false"
      :style="{ paddingBottom: !sharedStore.isShowBottomSafeArea ? 0 : 'auto' }"
    >
      <van-tabbar-item name="index" icon="home-o" to="/">
        <span>CS2</span>
        <template #icon>
          <span class="icon icon-csgo" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="knapsack" icon="friends-o" :dot="sharedStore.isKnapsackDotShow" to="/knapsack">
        <span>背包</span>
        <template #icon>
          <span class="icon icon-tiqushipin1" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="recharge" icon="setting-o" to="/pay?from=luckyItem&luckyScene=buy">
        <span>充值中心</span>
        <div v-if="rechargeRewardParam.showIcon" class="style-bot">
          <span class="style-bot__text">{{ rechargeRewardParam.type == 1 ? '首充' : '限时' }}</span>
          <span class="style-bot__text__two">多送</span>
        </div>
        <template #icon>
          <span class="icon icon-chongzhizhongxin1" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </client-only>
</template>

<script setup lang="ts">
// import Cookies from 'js-cookie'

import { useSharedStore } from '../stores/shared'
// import { px2rem } from '../utils/index'
import { isAndroidDevice, isiPhone, isiPhoneSafari } from '~/utils/device'
import { getRechargeReward } from '@/apis/modules/home'

const sharedStore = useSharedStore()

// const isInApp = computed(() => Cookies.get('C5Channel')?.toLowerCase().trim() !== '' && !!Cookies.get('C5AccessToken'))
const isShowBottomSafeArea = ref(false)
const rechargeRewardParam = reactive({
  showIcon: false,
  type: 1
})

// const bottomSafeAreaPadding = computed(() => {
//   return isShowBottomSafeArea.value ? `${px2rem(25)}rem` : 0
// })

onMounted(async () => {
  await nextTick()

  //  isInApp.value &&
  isShowBottomSafeArea.value = isAllScreenDisplay() && ((isiPhone() && !isiPhoneSafari()) || (isAndroidDevice() && isInApp)) // safari浏览器底部是地址栏，不需要适配安全区

  // 活动读取
  getRechargeReward({
    module: 'luckyItem'
  }).then((res: any) => {
    if (res?.config && res.config[0]) {
      rechargeRewardParam.showIcon = true
      rechargeRewardParam.type = res.type
    }
  })
})
</script>

<style lang="scss" scoped>
.van-tabbar--fixed {
  padding-bottom: 25px;
  position: fixed !important;

  .van-tabbar-item {
    position: relative;

    .style-bot {
      position: absolute;
      top: 0;
      left: 48%;
      background: #eb4b4b;
      line-height: 16px;
      color: $color-white;
      padding: 0px 4px;
      border-radius: 15px;
      // transform: scale(.9);
      // -webkit-transform: scale(.9);
      font-size: 10px;
      transform-origin: left bottom;
      -webkit-transform-origin: left bottom;
      animation: botaniamate 1s linear infinite;
      -webkit-animation: botaniamate 1s linear infinite;

      &__text,
      &__text__two {
        display: block;
        font-weight: 700;
      }

      &__text__two {
        background: #eb4b4b;
        opacity: 1;
        border-radius: 12px;
        padding: 0 1px;
        position: absolute;
        left: 3px;
        top: 0;
        padding: 0 1px;
        animation: bottextaniamate 2s linear infinite;
        -webkit-animation: bottextaniamate 2s linear infinite;
      }
    }
  }

  @keyframes botaniamate {
    0% {
      transform: scale(0.8) rotate(0deg);
    }

    50% {
      transform: scale(0.8) rotate(-12deg);
    }

    70% {
      transform: scale(0.8) rotate(8deg);
    }

    80% {
      transform: scale(0.8) rotate(0deg);
    }

    100% {
      transform: scale(0.8) rotate(0deg);
    }
  }
  @-webkit-keyframes botaniamate {
    0% {
      transform: scale(0.8) rotate(0deg);
    }

    50% {
      transform: scale(0.8) rotate(-12deg);
    }

    70% {
      transform: scale(0.8) rotate(8deg);
    }

    80% {
      transform: scale(0.8) rotate(0deg);
    }

    100% {
      transform: scale(0.8) rotate(0deg);
    }
  }

  @keyframes bottextaniamate {
    0% {
      opacity: 0;
    }
    34% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    51% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    86% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes bottextaniamate {
    0% {
      opacity: 0;
    }
    34% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    51% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    86% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
